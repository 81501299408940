import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { rideTheme } from 'common/theme/ride';
import Seo from 'components/seo';
import Footer from 'containers/Ride/Footer';
import TermsAndConditions from 'containers/Loobi/Legal/TermsAndConditions';
import NavbarInternal from 'containers/Ride/NavbarInternal';
import { ContentWrapper, GlobalStyle } from 'containers/Ride/ride.style';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

const LoobiLegalTyC = () => {
  return (
    <ThemeProvider theme={rideTheme}>
      <Fragment>
        <Seo title="Condiciones legales de Loobi" />
        <Helmet>
          <link rel="canonical" href="https://www.loobi.com/legal/condiciones-de-uso/"/>
          <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/a370565f2585f5f2f12fe533/script.js"/>
        </Helmet>
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <NavbarInternal />
            </DrawerProvider>
          </Sticky>
          <TermsAndConditions />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default LoobiLegalTyC;
