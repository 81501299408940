import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import { TermsAndConditionsWrapper } from './termsAndConditionsContent.style';

const TermsAndConditions = ({
  sectionWrapper,
  title,
  textArea
}) => {
  
  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <TermsAndConditionsWrapper>
          <Box {...textArea}>
            <Heading content="Términos y Condiciones" {...title} />
            <div class="description">
              <p>Estos Términos y Condiciones regulan la descarga, acceso y utilización de la aplicación móvil (en adelante, la “APLICACIÓN”), que Loobi Hub SL ponen a disposición de los usuarios. Esta versión de la APLICACIÓN está disponible de forma gratuita. El acceso a la APLICACIÓN supone que el usuario reconoce ha aceptado y consentido sin reservas de las presentes condiciones de uso.</p>

              <p>Nuestros clientes son lo principal para nosotros y para ellos tenemos servicios y productos excepcionales. Contáctanos para saber más acerca de lo que ofrecemos.</p>

              <h3>1. Objeto</h3>
              <p>La APLICACIÓN tiene el objetivo del entretenimiento. Algunos de los colectivos que pueden beneficiarse de este proyecto son para todos los públicos. En el diseño y desarrollo de esta APLICACIÓN han intervenido profesionales especialistas así como un grupo de usuarios que participaron en el periodo de prueba.</p>

              <p>La APLICACIÓN se pone a disposición de los usuarios para su uso personal (nunca empresarial). Funciona en un teléfono móvil Android o iOS.</p>

              <h3>2. Derechos de propiedad intelectual e industrial</h3>
              <p>Los derechos de propiedad intelectual e industrial sobre la APLICACIÓN son titularidad de Loobi Hub SL, correspondiéndole el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación.</p>

              <p>Los terceros titulares de derechos de propiedad intelectual e industrial sobre fotografías, logotipos, y cualesquiera otros símbolos o contenidos incluidos en la APLICACIÓN han concedido las correspondientes autorizaciones para su reproducción, distribución y puesta a disposición del público.</p>

              <p>El usuario reconoce que la reproducción, modificación, distribución, comercialización, descompilación, desensamblado, utilización de técnicas de ingeniería inversa o de cualquier otro medio para obtener el código fuente, transformación o publicación de cualquier resultado de pruebas de referencias no autorizadas de cualquiera de los elementos y utilidades integradas dentro del desarrollo constituye una infracción de los derechos de propiedad intelectual de Loobi Hub SL, obligándose, en consecuencia, a no realizar ninguna de las acciones mencionadas.</p>

              <h3>3. Politica de privacidad</h3>
              <p>La APLICACIÓN utilizará Google Analytics como herramienta para conocer uso y las tendencias de interacción de la misma. Loobi Hub SL podrán utilizar la información de carácter personal que nos facilite de forma disociada (sin identificación personal) para fines internos, tales como la elaboración de estadísticas.</p>

              <p>La APLICACIÓN podrá recabar, almacenar o acumular determinada información de carácter no personal referente a su uso. Google Analytics se rige por las condiciones generales de Google accesibles en <a href="http://www.google.com/analytics/terms/es.html">http://www.google.com/analytics/terms/es.html</a> y las políticas de privacidad en Google accesibles en <a href="https://www.google.es/intl/es/policies/privacy/">https://www.google.es/intl/es/policies/privacy/</a>. Para proceder a la desactivación de Google Analytics: <a href="https://support.google.com/analytics/answer/1009696?hl=es">https://support.google.com/analytics/answer/1009696?hl=es</a>.</p>

              <p>De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), se informa que los datos de carácter personal proporcionados mediante la aceptación de estos Términos y Condiciones, formarán parte de un fichero responsabilidad de Loobi Hub SL y que estos serán tratados con la finalidad descrita en el apartado “1. OBJETO” de este documento y serán conservados mientras dure la relación contractual objeto del uso de la APLICACIÓN, con el único objetivo de facilitar la introducción de mejoras en futuras versiones de la APLICACIÓN, también podrá realizarse el tratamiento de la información de las instalaciones, accesos de usuarios, datos demográficos, pantallas e interacción del usuario y bloqueos y excepciones.</p>

              <p>Asimismo, se informa que podrá retirar el consentimiento.</p>

              <p>Loobi usa pagos de Stripe, los cuales requieren información sobre tu número de teléfono y de las apps instaladas en el dispositivo para asegurarnos de un pago satisfactorio y seguro.</p>

              <h3>4. Exclusión de responsabilidad</h3>
              <p>Loobi Hub SL se reserva el derecho de editar, actualizar, modificar, suspender, eliminar o finalizar los servicios ofrecidos por la Aplicación, incluyendo todo o parte de su contenido, sin necesidad de previo aviso, así como de modificar la forma o tipo de acceso a esta.</p>

              <p>Las posibles causas de modificación pueden tener lugar, por motivos tales, como su adaptación a las posibles novedades legislativas y cambios en la propia Aplicación, así como a las que se puedan derivar de los códigos tipos existentes en la materia o por motivos estratégicos o corporativos. Loobi Hub SL no será responsable del uso de la APLICACIÓN por un menor de edad, siendo la descarga y uso de la APLICACIÓN de la exclusiva responsabilidad del usuario.</p>

              <p>La APLICACIÓN se presta “tal y como es” y sin ninguna clase de garantía. Loobi Hub SL no se hace responsable de la calidad final de la APLICACIÓN ni de que ésta sirva y cumpla con todos los objetivos de la misma. No obstante lo anterior, Loobi Hub SL se compromete en la medida de sus posibilidades a contribuir a mejorar la calidad de la APLICACIÓN , pero no puede garantizar la precisión ni la actualidad del contenido de la misma. La responsabilidad de uso de la APLICACIÓN corresponde solo al usuario.</p>

              <p>Salvo lo establecido en estos Términos y Condiciones, Loobi Hub SL no es responsable de ninguna pérdida o daño que se produzca en relación con la descarga o el uso de la APLICACIÓN, tales como los producidos como consecuencia de fallos, averías o bloqueos en el funcionamiento de la APLICACIÓN (por ejemplo, y sin carácter limitativo: error en las líneas de comunicaciones, defectos en el hardware o software de la APLICACIÓN o fallos en la red de Internet). Igualmente, Loobi Hub SL tampoco será responsable de los daños producidos como consecuencia de un uso indebido o inadecuado de la APLICACIÓN por parte de los usuarios.</p>

              <h3>5. Legislación y fuero</h3>
              <p>El usuario acepta que la legislación aplicable y los Juzgados y Tribunales competentes para conocer de las divergencias derivadas de la interpretación o aplicación de este clausulado son los españoles, y se somete, con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales más cercanos a la ciudad de Madrid.</p>
            </div>
          </Box>
        </TermsAndConditionsWrapper>
      </Container>
    </Box>
  );
};


TermsAndConditions.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

TermsAndConditions.defaultProps = {
  sectionWrapper: {},
  textArea: {
    width: ['100%', '100%', '100%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '15px', '18px', '22px', '22px'],
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  description: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  }
};

export default TermsAndConditions;
